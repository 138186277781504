// 成本管理相关接口
import request from "@/request/request";

// 订单列表
export const OrderList = (params) => request.post(`/agent/admin/order/orderlist`, params);

// 获取代理商可提现列表
export const CandrawcashList = (params) => request.post(`/agent/admin/proceeds/candrawcashlist`, params);

// 申请提现
export const ApplyDrwcash = (params) => request.post(`/agent/admin/proceeds/applydrawcash`, params);

// 提现申请列表
export const ApplyDrawcaShlist = (params) => request.post(`/agent/admin/proceeds/applydrawcashlist`, params);

// 提现审核-已打款
export const AreeApply = (params) => request.post(`/agent/admin/proceeds/agreeapply`, params);

// 提现审核-拒绝
export const RefuseApply = (params) => request.post(`/agent/admin/proceeds/refuseapply`, params);

// 提现记录
export const DrawcaShrecord = (params) => request.post(`/agent/admin/proceeds/drawcashrecord`, params);

// 导出订单
export const ExportOrder = (params) => request.post(`/agent/admin/order/exportorder`, params);

// 某个代理订单列表
export const SomeonOrders = (params) => request.post(`/agent/admin/order/someonorders`, params);

// 历史订单列表
export const Oldorderlist = (params) => request.post(`/agent/admin/order/oldorderlist`, params);






