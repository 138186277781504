// 收益相关接口
import request from "@/request/request";

// 课程成本列表
export const Coursecost = (params) => request.post(`/agent/admin/business/coursecost`, params);

// 会员卡成本列表
export const UsermemBercost = (params) => request.post(`/agent/admin/business/usermembercost`, params);

// 保存业务成本
export const SaveBusinesscost = (params) => request.post(`/agent/admin/business/savebusinesscost`, params);

// 收益数据
export const ProceedSrecord = (params) => request.post(`/agent/admin/proceeds/proceedsrecord`, params);

// 收益头部数据
export const ProceedsTitle = (params) => request.post(`/agent/admin/proceeds/proceedstitle`,params);



