<template>
  <!-- 菜单管理页面 -->
	<div class="box">
		<div v-show="$route.meta.isShow">
			<!-- 顶部 -->
			<div class="header">
				<div class="title">我的收益</div>
				<div class="content">
					<div class="numBox">
						<div class="num">{{dataNun.totalProceeds || 0}}</div>
						<div class="size">累计收益(元)</div>
					</div>
					<div class="numBox">
						<div class="num">{{dataNun.onTheWayProceeds || 0}}</div>
						<div class="size">在途收益(元)</div>
					</div>
					<div class="numBox">
						<div class="num">{{dataNun.drawCash || 0}}</div>
						<div class="size">已提现(元)</div>
					</div>
					<div class="numBox">
						<div class="num">{{dataNun.canDrawCash || 0}}</div>
						<div class="size">可提现(元)</div>
					</div>
				</div>
			</div>
			<div class="tableStyle">
                <div class="sousuo">
                    <a-form layout="inline" :model="formState">
						<a-form-item label="状态" v-if="tabChange == 3">
							<a-select v-model="formState.status" allowClear placeholder="请选择状态" style="width: 248px">
								<a-select-option :value="0">全部</a-select-option>
                                <a-select-option :value="2">提现成功</a-select-option>
								<a-select-option :value="1">提现中</a-select-option>
                                <a-select-option :value="3">提现失败</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="类型" v-if="tabChange == 1 || tabChange == 2">
							<a-select v-model="formState.type" allowClear placeholder="请选择类型" style="width: 248px">
								<a-select-option :value="0">全部</a-select-option>
                                <a-select-option :value="1">精品馆课程</a-select-option>
								<a-select-option :value="2">会员卡</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="时间" v-if="tabChange != 0">
                            <a-range-picker v-model="formState.time" format="YYYY-MM-DD" style="width: 248px" @change="termChange"/>
						</a-form-item>
						<a-form-item>
							<a-button type="primary" html-type="submit"  v-if="tabChange != 0" @click="searchClick">搜索</a-button>
						</a-form-item>
					</a-form>
                </div>
				<div class="tabSwitch">
					<div class="tabBox">
						<div class="tabName" :class="{ tabStyle : tabChange == index }" v-for="(item,index) in tabList" :key="index" @click="tabClick(index)">{{item.name}}</div>
					</div>
                     <template v-if="agentLevel != 0">
                        <a-button v-if="isShowWithdrawal" type="primary" @click="withdrawalClick">申请提现</a-button>
                     </template>
				</div>
				
                <a-table v-show="tabChange != 3" :columns="columns" :data-source="tabledData" :row-key="record => record.id" :pagination="false" :loading="tableLoading" bordered>
                    <span slot="amount" slot-scope="amount, record">
                        <span v-if="record.isIncrease == 0 && record.amount != 0"> - </span>
                        <span v-else-if="record.isIncrease == 1 && record.amount != 0"> + </span>
                        <span>{{record.amount}}</span>
					</span>
                </a-table>
				<a-table v-show="tabChange == 3" :columns="columnsRecord" :data-source="tabledData" :row-key="record => record.applyTime" :pagination="false" :loading="tableLoading" bordered>
					<span slot="status" slot-scope="status, record">
                        <span v-if="record.status == 1"><a-badge color="#108ee9"/> 提现中</span>
                        <span v-else-if="record.status == 2"><a-badge color="#67C23A"/> 提现成功</span>
                        <span v-else><a-badge color="#F56C6C"/> 提现失败</span>
					</span>
				</a-table>
				
				<div class="page">
					<MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="tabledData.length > 0"/>
				</div>
			</div>
		</div>
		<router-view />
	</div>
</template>

<script>
import { codeFn,format } from "@/utils/tools";
import { DrawcaShrecord } from "@/request/api/orderManage";
import { ProceedSrecord,ProceedsTitle } from "@/request/api/profitManage";
import MyPagination from "@/components/pagination/MyPagination";
export default {
    components:{MyPagination},
    created () {
        this.agentLevel = JSON.parse(localStorage.getItem("user")).agentLevel // 0:超级管理员 1:市级代理 2:区域代理 3:推广大使
        this.proceedInit();
        this.headerInit()
        if (codeFn("/agent/admin/proceeds/applydrawcash")) this.isShowWithdrawal = true;
    },

    watch: {
        $route (to) {
            if (to.path === "/profitManage/incomeDetails") {
                this.proceedInit();
                this.headerInit()
            }
        },
    },
    
	data() {
        return {
            agentLevel:'',
            isShowWithdrawal: false,

            formState: {
                status: undefined,
                type: undefined,
                time:[],
            },
            
			pageNo: 1,
			pageSize:'',
            count: 0,
            
			tableLoading: true, // 表格的loading图
			// 切换标签
			tabChange:0,
			tabList: [
				{
					id: 1,
					name:'全部'
				}, {
					id: 2,
					name:'在途'
				}, {
					id: 3,
					name:'已到账'
				}, {
					id: 4,
					name:'提现记录'
				}
			],
			
			// 全部/在途/已到账表格
			columns: [
				{
					title: "金额",
					dataIndex: "amount",
					width:"33.3%",
                    key: "amount",
                    scopedSlots: { customRender: "amount" },
				},
				{
					title: "描述",
					dataIndex: "remark",
					width:"33.3%",
					key: "remark",
				},
				{
					title: "时间",
					dataIndex: "payTime",
					width:"33.3%",
					key: "payTime",
				}
			],
			// 提现记录表格
			columnsRecord: [
				{
					title: "金额",
					dataIndex: "amount",
					width:"33.3%",
                    key: "amount",
				},
				{
					title: "状态",
					dataIndex: "status",
					width:"33.3%",
					key: "status",
					scopedSlots: { customRender: "status" },
				},
				{
					title: "时间",
					dataIndex: "applyTime",
					width:"33.3%",
					key: "applyTime",
				}
			],
            tabledData: [],

            starTime: '',
            endTime: '',

            proceedsType: 0,
            // 头部数据
            dataNun: {
                canDrawCash: '',
                drawCash: '',
                onTheWayProceeds: '',
                totalProceeds: ''
            }
		};
	},
    methods: {
        // 搜索按钮 , 回车搜索
        searchClick () {
            this.pageNo = 1
            this.tabledData = []
			this.isDisableSearch = true;
			setTimeout(() => {
			    this.isDisableSearch = false;
            }, 3000);

			if (this.tabChange == 3) {
                this.withdrawalRecord()
            } else {
                this.proceedInit();
            }
        },
        
         // 有效期监听
        termChange (val) {
            if (val.length == 0) {
                this.starTime = ''
                this.endTime = ''
            } else {
                this.starTime = format(new Date(val[0]._d), "yyyy-MM-dd" +' '+'00:00:00');
                this.endTime = format(new Date(val[1]._d), "yyyy-MM-dd" + ' ' + '00:00:00');
            }
        },

        // 切换分页器
        showSizeChangeFn (current, pageSize) {
            this.tableLoading = true
            this.pageNo = current;
            if (this.tabChange == 0) {
                this.proceedsType = 0
                this.proceedInit();
            } else if (this.tabChange == 1) {
                this.proceedsType = 1
                this.proceedInit();
            } else if (this.tabChange == 2) {
                this.proceedsType = 2
                this.proceedInit();
            } else if (this.tabChange == 3) {
                this.withdrawalRecord()
            }
        },
        
		// 点击切换标签页
		tabClick(index) {
            this.tabChange = index
            this.tabledData =[]
            this.count = 0
            this.pageNo = 1
            this.starTime = ''
            this.endTime = ''
            this.formState.status = undefined
            this.formState.type = undefined
            this.formState.time = []
            if (index == 0) {
                this.proceedsType = 0
                this.proceedInit();
            } else if (index == 1) {
                this.proceedsType = 1
                this.proceedInit();
            } else if (index == 2) {
                this.proceedsType = 2
                this.proceedInit();
            } else if (index == 3) {
                this.withdrawalRecord()
            }
		},

		// 点击申请提现
		withdrawalClick() {
			this.$router.push(`/profitManage/incomeDetails/applyWithdrawal`);
		},

        // 提现记录接口
        withdrawalRecord () {
            DrawcaShrecord({
                page: this.pageNo,
                startTime: this.starTime,
                endTime: this.endTime,
                status: this.formState.status == undefined ? 0 : this.formState.status, 
            }).then(({ code, data }) => {
                if (code === 200) {
                    this.tableLoading = false;
                    this.tabledData = data.list;
                    this.count = data.count
                }
			});
        },
        //  全部、在途、已到账列表接口
        proceedInit () {
            ProceedSrecord({
                page: this.pageNo,
                startTime: this.starTime,
                endTime: this.endTime,
                proceedsType: this.proceedsType, //状态：1在途，2 已到账, 3 已提现
                productType:this.formState.type == undefined ? 0 : this.formState.type, //类型：0全部，1课程，2会员
            }).then(({ code, data }) => {
                if (code === 200) {
                    this.tableLoading = false;
                    this.tabledData = data.list;
                    this.count = data.count
                } else {
                    this.tableLoading = false;
                }
			});
        },

        //  头部数据接口
        headerInit () {
            ProceedsTitle({}).then(({ code, data }) => {
                if (code === 200) {
                    this.dataNun = data
                }
			});
        },
	},
};
</script>
 
<style lang="less" scoped>
.box {
  min-height: 100%;
  // background-color: #fff;
  padding-bottom: 56px;
  .header{
    width: 100%;
    background-color: #fff;
	margin-bottom: 16px;
    .title{
      padding: 14px 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);;
	  margin-bottom: 16px;
    }
	.content{
        width: 100%;
		display: flex;
		justify-content: space-between;
		.numBox{
            width: 25%;
			display: flex;
			flex-direction: column;
			padding: 14px 16px 52px;
			.num{
				font-size: 32px;
				font-family: DINAlternate-Bold, DINAlternate;
				font-weight: bold;
				color: #000000;
			}
			.size{
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #666666;
			}
		}
	}
  }
  .tableStyle{
	background-color: #fff;
	padding: 16px;
	.tabSwitch{
		width: 100%;
		display: flex;
		margin: 16px 0;
		justify-content: space-between;
		align-items: center;
		.tabBox{
			display: flex;
			.tabName{
				width: 112px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				background-color: #fff;
				font-size: 15px;
				color: #000;
				border: 1px solid #F1F1F1;
				cursor: pointer;
			}
			.tabStyle{
				background-color: #F5F5F5;
			}
		}
	}
	.page{
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;
        padding-right: 10px;
	}
  }
}
</style>